export enum GameState {
  NotStarted = "not_started",
  Started = "started",
  Finished = "finished",
}

export enum Collections {
  Rooms = "rooms",
  Players = "players",
  Roles = "roles",
  Assignments = "assignments",
  PicksLycan = "picksLycan",
  Games = "games",
  Moderation = "moderation",
}

export enum Team {
  Village = "Village",
  Wolf = "Wolf",
  ThirdParty = "Third Party",
}

export enum CheckProperties {
  Wolfy = "Wolfy",
}

export const Teams: Team[] = [Team.Village, Team.Wolf, Team.ThirdParty];
