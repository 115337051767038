import React, { useRef, useState } from "react";
import { withFirebase, WithFirebaseProps } from "../Firebase/context";
import { RouteComponentProps } from "react-router";
import {
  IonContent,
  IonPage,
  IonButton,
  IonList,
  IonItem,
  IonInput,
  IonModal,
  IonIcon,
  IonItemOption,
  IonItemOptions,
  IonItemSliding,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonListHeader,
} from "@ionic/react";
import HeaderComponent from "../components/HeaderComponent";
import {
  useCollectionData,
  useDocumentData,
} from "react-firebase-hooks/firestore";
import { favoriteData } from "./GamePage/GamePage";
import { useAuthState } from "react-firebase-hooks/auth";
import { close } from "ionicons/icons";
import { RoleWithData } from "../common/types";
import Footer from "../components/FooterComponent";

interface RoomPageProps
  extends RouteComponentProps<{
    roomID: string;
  }> {}

type game = {
  name: string;
  id: string;
};

const RoomPageBase: React.FC<WithFirebaseProps & RoomPageProps> = (props) => {
  const [user, initialising, userError] = useAuthState(props.firebase.auth);
  const [gameNameInput, setGameNameInput] = useState("");
  const [showFavoriteModel, setShowFavoriteModel] = useState(false);
  const [
    favoriteData,
    favoriteDataLoading,
    favoriteDateError,
  ] = useDocumentData<favoriteData>(
    props.firebase.db.collection("favorites").doc(user?.uid)
  );
  return (
    <IonPage>
      <HeaderComponent onLogOut={props.firebase.signUserOut} />
      <IonContent className="ion-padding">
        {favoriteData && (
          <FavoriteListModal
            villages={favoriteData}
            isOpen={showFavoriteModel}
            onDismiss={() => setShowFavoriteModel(false)}
            startGame={(favoriteVillageID) =>
              gameNameInput &&
              props.firebase
                .createNewGame(
                  gameNameInput,
                  props.match.params.roomID,
                  favoriteData[favoriteVillageID].roles
                )
                .then(() => setGameNameInput(""))
                .then(() => setShowFavoriteModel(false))
            }
            deleteFavorite={(favoriteVillageID) =>
              props.firebase.deleteFavoriteVillage(favoriteVillageID)
            }
          />
        )}
        <IonInput
          placeholder="Game name"
          value={gameNameInput}
          onIonChange={(e) =>
            setGameNameInput((e.target as HTMLInputElement).value)
          }
        >
          Game Name:&nbsp;
        </IonInput>
        <div>
          <IonButton
            disabled={!gameNameInput}
            onClick={() =>
              gameNameInput &&
              props.firebase
                .createNewGame(gameNameInput, props.match.params.roomID)
                .then(() => setGameNameInput(""))
            }
          >
            Start Game
          </IonButton>
          {favoriteData && (
            <IonButton
              disabled={!gameNameInput}
              onClick={() => setShowFavoriteModel(true)}
            >
              Start new game from favorites
            </IonButton>
          )}
          <GameList
            firebase={props.firebase}
            roomID={props.match.params.roomID}
          />
        </div>
      </IonContent>
      <Footer />
    </IonPage>
  );
};

type GameListProps = {
  roomID: string;
};

const GameList: React.FC<WithFirebaseProps & GameListProps> = (props) => {
  const [games, gamesLoading, gamesError] = useCollectionData<game>(
    props.firebase.db
      .collection("games")
      .where("roomName", "==", props.roomID)
      .orderBy("date", "desc"),
    { idField: "id" }
  );
  if (gamesLoading) {
    return null;
  }
  return (
    <div>
      Your Games:
      <IonList>
        {games &&
          games.map((game: game, index: number) => {
            return (
              <IonItem key={index} routerLink={`/game/${game.id}`}>
                {game.name}
              </IonItem>
            );
          })}
      </IonList>
    </div>
  );
};

type FavoriteListModalProps = {
  isOpen: boolean;
  onDismiss: () => void;
  villages: favoriteData;
  startGame: (favoriteVillageID: string) => void;
  deleteFavorite: (favoriteVillageID: string) => void;
};

const FavoriteListModal: React.FC<FavoriteListModalProps> = (props) => {
  const ionList = useRef<HTMLIonListElement>(null);
  const villageMap = new Map<
    number,
    Array<{
      villageID: string;
      villageName: string;
      roles: { [key: string]: RoleWithData };
    }>
  >();
  for (const village in props.villages) {
    const count = Object.values(props.villages[village].roles).reduce(
      (roleCount, role) => roleCount + role.quantity,
      0
    );
    const currentValueOfMap = villageMap.get(count);
    if (currentValueOfMap) {
      villageMap.set(
        count,
        currentValueOfMap.concat({
          ...props.villages[village],
          villageID: village,
        })
      );
    } else {
      villageMap.set(count, [
        { ...props.villages[village], villageID: village },
      ]);
    }
  }
  return (
    <IonModal isOpen={props.isOpen} onDidDismiss={props.onDismiss}>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Test</IonTitle>
          <IonButton slot="end" onClick={props.onDismiss}>
            <IonIcon icon={close} />
          </IonButton>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonList ref={ionList}>
          {Array.from(villageMap.keys()).map((villageSize) => {
            return (
              <>
                <IonListHeader>{villageSize}</IonListHeader>
                {villageMap.get(villageSize)?.map((village) => {
                  return (
                    <IonItemSliding>
                      <IonItem
                        onClick={() => props.startGame(village.villageID)}
                      >
                        {village.villageName}
                      </IonItem>
                      <IonItemOptions side="end">
                        <IonItemOption
                          color="danger"
                          onClick={() => {
                            if (ionList.current) {
                              ionList.current.closeSlidingItems();
                            }
                            props.deleteFavorite(village.villageID);
                          }}
                        >
                          Delete
                        </IonItemOption>
                      </IonItemOptions>
                    </IonItemSliding>
                  );
                })}
              </>
            );
          })}
        </IonList>
      </IonContent>
    </IonModal>
  );
};

export default withFirebase(RoomPageBase);
