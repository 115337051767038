import React, { useState } from "react";

import {
  IonContent,
  IonPage,
  IonButton,
  IonInput,
  IonList,
  IonItem,
} from "@ionic/react";
import Firebase from "../Firebase";
import { withFirebase, WithFirebaseProps } from "../Firebase/context";
import { RouteComponentProps } from "react-router";
import HeaderComponent from "../components/HeaderComponent";
import { useAuthState } from "react-firebase-hooks/auth";
import { useDocumentData } from "react-firebase-hooks/firestore";
import Footer from "../components/FooterComponent";

const Home: React.FC<RouteComponentProps> = (props) => {
  return (
    <HomeBody
      createRoomCallback={(roomID: string) => {
        props.history.push(`/room/${roomID}`);
      }}
      joinRoomCallback={(roomID: string) => {
        props.history.push(`/room/${roomID}`);
      }}
    />
  );
};

type HomeBodyBaseProps = {
  joinRoomCallback: (roomID: string) => void;
  createRoomCallback: (roomID: string) => void;
};

type userData = {
  id: string;
  rooms: string[];
};

const HomeBodyBase: React.FC<HomeBodyBaseProps & WithFirebaseProps> = (
  props
) => {
  const [roomNameInput, setRoomNameInput] = useState("");
  return (
    <IonPage>
      <HeaderComponent onLogOut={props.firebase.signUserOut} />
      <IonContent className="ion-padding">
        <IonInput
          placeholder="Room name"
          value={roomNameInput}
          onIonChange={(e) =>
            setRoomNameInput((e.target as HTMLInputElement).value)
          }
        >
          Room Name:&nbsp;
        </IonInput>
        <IonButton
          onClick={() => {
            createRoom(roomNameInput, props.createRoomCallback, props.firebase);
          }}
        >
          Create a room
        </IonButton>
        <IonButton
          onClick={() => {
            joinRoom(roomNameInput, props.joinRoomCallback, props.firebase);
          }}
        >
          Join Room
        </IonButton>
        <RoomList firebase={props.firebase} />
      </IonContent>
      <Footer />
    </IonPage>
  );
};

const RoomList: React.FC<WithFirebaseProps> = (props) => {
  const [user, initialising, userError] = useAuthState(props.firebase.auth);
  const [userData, userDataLoading, userDataError] = useDocumentData<userData>(
    props.firebase.db.collection("users").doc(user ? user.uid : ""),
    { idField: "id" }
  );
  if (initialising || userDataLoading) {
    return null;
  }
  return (
    <div>
      Your Rooms:
      <IonList>
        {userData &&
          userData.rooms.map((room: string, index: number) => {
            return (
              <IonItem key={index} routerLink={`/room/${room}`}>
                {room}
              </IonItem>
            );
          })}
      </IonList>
    </div>
  );
};

const HomeBody = withFirebase(HomeBodyBase);

const createRoom = (
  roomName: string,
  callback: (roomID: string) => void,
  firebase: Firebase
) => {
  if (roomName) {
    firebase.createNewRoom(roomName).then((createdNewRoom) => {
      if (createdNewRoom) {
        callback(roomName);
      }
    });
  }
};

const joinRoom = (
  roomName: string,
  callback: (roomID: string) => void,
  firebase?: Firebase
) => {
  if (firebase && roomName) {
    firebase.getRoomExists(roomName).then(async (roomExists) => {
      if (roomExists) {
        const playerAddedToRoom = await firebase.addPlayerToRoom(roomName);
        if (playerAddedToRoom) {
          callback(roomName);
        }
      }
    });
  }
};

export default Home;
