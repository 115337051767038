import React from "react";

type playerCountProps = {
  title: string;
  count: number;
};
const CountComponent: React.FC<playerCountProps> = (props) => {
  return (
    <div className="CountComponent__root">
      <div className="CountComponent__title">{props.title}</div>
      <div className="CountComponent__count">{props.count}</div>
    </div>
  );
};

export default CountComponent;
