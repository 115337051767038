import React from "react";
import Firebase, { getFirebaseInstance } from "./firebase";

const FirebaseContext = React.createContext<Firebase>(getFirebaseInstance());

export type WithFirebaseProps = {
  firebase: Firebase;
};

export const withFirebase = <P extends object>(
  WrappedComponent: React.ComponentType<P & WithFirebaseProps>
) => {
  return (props: P) => {
    return (
      <FirebaseContext.Consumer>
        {(firebase) => <WrappedComponent {...props} firebase={firebase} />}
      </FirebaseContext.Consumer>
    );
  };
};

export default FirebaseContext;
