import "./LoginScreen.css";

import React, { FormEvent, useState } from "react";
import {
  IonContent,
  IonPage,
  IonButton,
  IonLabel,
  IonImg,
  IonItem,
  IonInput,
} from "@ionic/react";
import { withFirebase, WithFirebaseProps } from "../Firebase/context";
import { StyledFirebaseAuth } from "react-firebaseui";
import Footer from "../components/FooterComponent";

type LoginPageBaseProps = {
  setHasDisplayName: (hasDisplayName: boolean) => void;
};

const LoginPageBase: React.FC<LoginPageBaseProps & WithFirebaseProps> = (
  props
) => {
  const [username, setUsername] = useState("");
  const handleLoginSubmit = (e: FormEvent) => {
    e.preventDefault();
    if (username) {
      props.firebase &&
        props.firebase.signUserInAnonymously().then(() => {
          if (props.firebase) {
            const user = props.firebase.getCurrentUser();
            if (user) {
              user
                .updateProfile({
                  displayName: username,
                })
                .then(() => props.setHasDisplayName(true));
            }
          }
        });
    }
  };
  return (
    <IonPage className="LoginScreen__root">
      <IonContent className="ion-padding">
        <IonImg className="LoginScreen__image" src="assets/icon/wolf.svg" />
        <IonItem className="LoginScreen__title" lines="none">
          <IonLabel>Werewolf Buddy</IonLabel>
        </IonItem>
        {props.firebase.firebaseUIConfig && (
          <StyledFirebaseAuth
            uiConfig={props.firebase.firebaseUIConfig}
            firebaseAuth={props.firebase.auth}
          />
        )}
        <IonLabel className="LoginScreen__label">Or</IonLabel>
        <form onSubmit={(e) => handleLoginSubmit(e)} action="post">
          <IonItem lines="none">
            <IonInput
              placeholder="name"
              className="LoginScreen__name-input"
              required={true}
              value={username}
              onIonChange={(e) => setUsername((e.target as any).value)}
            />
          </IonItem>
          <IonItem lines="none">
            <IonButton
              type="submit"
              className="LoginScreen__button"
              size="large"
            >
              Guest Login
            </IonButton>
          </IonItem>
        </form>
      </IonContent>
      <Footer />
    </IonPage>
  );
};

export default withFirebase(LoginPageBase);
