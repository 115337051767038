import "./HeaderComponent.css";

import React from "react";
import {
  IonButton,
  IonButtons,
  IonHeader,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { Link, useHistory } from "react-router-dom";

type HeaderComponentProps = {
  onLogOut?: () => void;
};

const HeaderComponent: React.FC<HeaderComponentProps> = (props) => {
  const history = useHistory();
  return (
    <IonHeader className="HeaderComponent__root">
      <IonToolbar>
        <Link to={"/home"} style={{ textDecoration: "none", color: "black" }}>
          <IonTitle slot="primary">
            <img
              className="HeaderComponent__icon"
              src={"assets/icon/favicon.png"}
              alt=""
            />
            <span className="HeaderComponent__text">Werewolf Buddy</span>
          </IonTitle>
        </Link>
        {props.onLogOut && (
          <IonButtons slot="end">
            <IonButton
              onClick={() => {
                history.push("/profile");
              }}
            >
              {" "}
              Profile{" "}
            </IonButton>
            <IonButton onClick={props.onLogOut}>Log Out</IonButton>
          </IonButtons>
        )}
      </IonToolbar>
    </IonHeader>
  );
};

export default HeaderComponent;
