import React, { useEffect, useState } from "react";
import {
  IonButton,
  IonContent,
  IonInput,
  IonItem,
  IonLabel,
  IonPage,
  IonTitle,
} from "@ionic/react";
import HeaderComponent from "../../components/HeaderComponent";
import { withFirebase, WithFirebaseProps } from "../../Firebase/context";
import Footer from "../../components/FooterComponent";
import { text } from "ionicons/icons";
import { useAuthState } from "react-firebase-hooks/auth";

type ProfilePageProps = {};

const ProfilePage: React.FC<ProfilePageProps & WithFirebaseProps> = (props) => {
  const [user, initialising, userError] = useAuthState(props.firebase.auth);
  const [displayName, setDisplayName] = useState("");
  useEffect(() => {
    if (user?.displayName) {
      setDisplayName(user.displayName);
    }
  }, [user]);
  return (
    <IonPage>
      <HeaderComponent
        onLogOut={props.firebase.user && props.firebase.signUserOut}
      />
      <IonContent className="ion-padding">
        <IonItem>
          <IonLabel>Name:</IonLabel>
          <IonInput
            value={displayName}
            placeholder="Username"
            onIonChange={(e) => setDisplayName(e.detail.value!)}
          />
        </IonItem>
        <IonButton
          onClick={() => user?.updateProfile({ displayName: displayName })}
        >
          Submit
        </IonButton>
      </IonContent>
      <Footer />
    </IonPage>
  );
};

export default withFirebase(ProfilePage);
