import React, { useEffect, useRef, useState } from "react";
import { IonAlert } from "@ionic/react";

type YourRoleModalProps = {
  roleName?: string;
};
const YourRoleAlert: React.FC<YourRoleModalProps> = (props) => {
  const [showAlert, setShowAlert] = useState(false);
  useEffect(() => {
    props.roleName && setShowAlert(true);
  }, [props.roleName]);
  return (
    <IonAlert
      isOpen={showAlert}
      onDidDismiss={() => setShowAlert(false)}
      header={"Your Role"}
      message={"Role: " + props.roleName}
      buttons={["Ok"]}
    />
  );
};

export default YourRoleAlert;
