import React from "react";
import { IonContent, IonLabel, IonPage, IonTitle } from "@ionic/react";
import HeaderComponent from "../../components/HeaderComponent";
import { withFirebase, WithFirebaseProps } from "../../Firebase/context";
import Footer from "../../components/FooterComponent";

type AcknowledgmentsPageProps = {};

const AcknowledgmentsPage: React.FC<
  AcknowledgmentsPageProps & WithFirebaseProps
> = (props) => {
  return (
    <IonPage>
      <HeaderComponent
        onLogOut={props.firebase.user && props.firebase.signUserOut}
      />
      <IonContent className="ion-padding">
        <h2>Special Thanks</h2>
        <ul>
          <li>
            KMAP & Tony Sadak: Thanks for being early adopters, lots of user
            feedback, and feature suggestions
          </li>
          <li>
            SDJ: Thanks for being the beta group and putting up with all of the
            bugs
          </li>
        </ul>
        <h2>Attribution</h2>
        <ul>
          <li>
            <img
              className="HeaderComponent__icon"
              src={"assets/icon/favicon.png"}
              alt=""
            />
            Twitter,{" "}
            <a href="https://commons.wikimedia.org/wiki/File:Twemoji12_1f43a.svg">
              Twemoji12 1f43a
            </a>
            ,{" "}
            <a
              href="https://creativecommons.org/licenses/by/4.0/legalcode"
              rel="license"
            >
              CC BY 4.0
            </a>
          </li>
        </ul>
      </IonContent>
      <Footer />
    </IonPage>
  );
};

export default withFirebase(AcknowledgmentsPage);
