import React, { useEffect, useState } from "react";
import { Redirect, Route } from "react-router-dom";
import { IonApp, IonRouterOutlet } from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import Home from "./pages/Home";
import GamePage from "./pages/GamePage/GamePage";
import { FirebaseContext } from "./Firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import { getFirebaseInstance } from "./Firebase/firebase";
import LoginScreen from "./pages/LoginScreen";
import RoomPage from "./pages/RoomPage";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

/* Theme variables */
import "./theme/variables.css";
import AcknowledgmentsPage from "./pages/AcknowledgmentsPage/AcknowledgmentsPage";
import ProfilePage from "./pages/ProfilePage/ProfilePage";

const firebase = getFirebaseInstance();

const App: React.FC = () => {
  const [user, initialising, error] = useAuthState(firebase.auth);
  const [hasDisplayName, setHasDisplayName] = useState(false);
  useEffect(() => {
    if (user && user.displayName && !hasDisplayName) {
      setHasDisplayName(true);
    }
  });
  if (error) {
    console.log(error);
  }
  if (initialising) {
    return <div>Loading</div>;
  }
  return (
    <FirebaseContext.Provider value={firebase}>
      <IonApp>
        <IonReactRouter>
          {/*<IonRouterOutlet>*/}
          <Route
            path="/home"
            exact={true}
            render={(props) => {
              return user && hasDisplayName ? (
                <Home {...props} />
              ) : (
                <LoginScreen setHasDisplayName={setHasDisplayName} />
              );
            }}
          />
          <Route
            path="/room/:roomID"
            exact={true}
            render={(props) => {
              return user && hasDisplayName ? (
                <RoomPage {...props} />
              ) : (
                <LoginScreen setHasDisplayName={setHasDisplayName} />
              );
            }}
          />
          <Route
            path="/game/:gameID"
            exact={true}
            render={(props) => {
              return user && hasDisplayName ? (
                <GamePage {...props} />
              ) : (
                <LoginScreen setHasDisplayName={setHasDisplayName} />
              );
            }}
          />
          <Route
            path="/acknowledgments"
            exact={true}
            render={(props) => {
              return <AcknowledgmentsPage {...props} />;
            }}
          />
          <Route
            path="/profile"
            exact={true}
            render={(props) => {
              return user && hasDisplayName ? (
                <ProfilePage {...props} />
              ) : (
                <LoginScreen setHasDisplayName={setHasDisplayName} />
              );
            }}
          />
          <Route exact={true} path="/" render={() => <Redirect to="/home" />} />
          {/*</IonRouterOutlet>*/}
        </IonReactRouter>
      </IonApp>
    </FirebaseContext.Provider>
  );
};

export default App;
