import React, {useEffect, useRef, useState} from "react";
import {RouteComponentProps} from "react-router";
import {
  IonButton,
  IonCheckbox,
  IonCol,
  IonContent,
  IonFab,
  IonFabButton,
  IonGrid,
  IonIcon,
  IonInput,
  IonItem,
  IonItemOption,
  IonItemOptions,
  IonItemSliding,
  IonLabel,
  IonList,
  IonListHeader,
  IonModal,
  IonNote,
  IonPage,
  IonRadio,
  IonRadioGroup,
  IonRow,
  IonTextarea,
  IonTitle,
} from "@ionic/react";
import {withFirebase, WithFirebaseProps} from "../../Firebase/context";
import Firebase from "../../Firebase";
import * as firebase from "firebase";
import CountComponent from "../../components/CountComponent";
import {CheckProperties, Collections, GameState, Team, Teams,} from "../../common/enums";
import {useAuthState} from "react-firebase-hooks/auth";
import {useDocumentData} from "react-firebase-hooks/firestore";
import HeaderComponent from "../../components/HeaderComponent";
import {RoleWithData} from "../../common/types";
import "./GamePage.css";
import {eye, star} from "ionicons/icons";
import YourRoleAlert from "./YourRoleAlert";
import Footer from "../../components/FooterComponent";

interface GamePageProps
  extends RouteComponentProps<{
    gameID: string;
  }> {}

const GamePage: React.FC<GamePageProps> = (props) => {
  return (
    <IonPage>
      <HeaderComponent />
      <IonContent className="ion-padding">
        <GameDetails gameID={props.match.params.gameID} />
      </IonContent>
      <Footer />
    </IonPage>
  );
};

type GameDetailsBaseProps = {
  gameID: string;
} & WithFirebaseProps;

type roleAssignment = {
  roleID: string;
  roleName: string;
  playerID: string;
  checks?: { [key: string]: string };
  usedCheck?: boolean;
};

type lycanData = {
  [key: string]: string;
};

type player = {
  id: string;
  name: string;
};

type gameData = {
  gameState: GameState;
  name: string;
  owner: string;
  moderator: string;
  players: { [key: string]: string };
  lycanSet?: boolean;
  roles: { [key: string]: RoleWithData };
};

type moderationData = {
  [playerID: string]: moderationRoleData;
};

type moderationRoleData = {
  roleID: string;
  roleName: string;
  team: Team;
  wolfy: boolean;
  picksLycan: boolean;
};

export type favoriteData = {
  [gameID: string]: {
    villageName: string;
    roles: { [key: string]: RoleWithData };
  };
};

const GameDetailsBase: React.FC<GameDetailsBaseProps & WithFirebaseProps> = (
  props
) => {
  const [user, initialising, userError] = useAuthState(props.firebase.auth);
  const gameRef = props.firebase.db.collection("games").doc(props.gameID);
  const [gameData, gameDataLoading, gameDataError] = useDocumentData<gameData>(
    gameRef
  );
  useEffect(() => {
    if (
      user &&
      gameData &&
      gameData.gameState === GameState.NotStarted &&
      gameData.moderator !== user.uid &&
      gameData.owner != user.uid
    ) {
      props.firebase.addPlayerToGame(props.gameID);
    }
  }, [user, gameData?.gameState, gameData?.moderator, gameData?.owner]);
  const [
    favoriteData,
    favoriteDataLoading,
    favoriteDateError,
  ] = useDocumentData<favoriteData>(
    props.firebase.db.collection("favorites").doc(user?.uid)
  );
  const [
    yourRoleAssignment,
    yourRoleAssignemntLoading,
    yourRoleAssignemntError,
  ] = useDocumentData<roleAssignment>(
    gameRef.collection("assignments").doc(user && user.uid),
    { idField: "playerID" }
  );
  const [vibratedForLycan, setVibratedForLycan] = useState<boolean>(false);
  if (
    initialising ||
    yourRoleAssignemntLoading ||
    gameDataLoading ||
    favoriteDataLoading ||
    !gameData ||
    !user
  ) {
    return null;
  }
  const isOwner = gameData.owner === (user && user.uid);
  const isModerator = gameData.moderator === user?.uid;
  if (gameData.lycanSet && isOwner && !vibratedForLycan) {
    window.navigator.vibrate(600);
    setVibratedForLycan(true);
  }
  const assignNewModerator = (playerID: string) => {
    if(window.confirm("Are you sure?")) {
      props.firebase.assignModerator(props.gameID, playerID);
    }
  }

  const createModeratorSlideAction: SlideAction | undefined =  isOwner && !gameData.moderator && gameData.gameState == GameState.Started ? { actionName: "Make Moderator", fn: assignNewModerator}: undefined

  const roleCount = gameData
    ? Object.values(gameData.roles).reduce((roleCount: number, role) => {
        return (roleCount + role.quantity) as number;
      }, 0)
    : 0;
  const playerCount = Object.keys(gameData.players).length;
  const player = gameData.players[user.uid];
  const yourRole =
    gameData.roles[(yourRoleAssignment && yourRoleAssignment.roleID) || ""];
  const startGame = () => {
    if (roleCount === playerCount) {
      props.firebase.startGame(props.gameID);
    }
  };

  const filteredRoles =
    gameData.roles &&
    Object.values(gameData.roles)
      .sort((roleA, roleB) => {
        if (roleA.name.toUpperCase() < roleB.name.toUpperCase()) {
          return -1;
        }
        if (roleA.name.toUpperCase() > roleB.name.toUpperCase()) {
          return 1;
        }
        return 0;
      })
      .filter((role) => {
        return (
          role.quantity !== 0 ||
          (gameData.gameState === GameState.NotStarted && isOwner)
        );
      });

  const EvilTeam = filteredRoles.filter((role) => {
    return role.team === Team.Wolf;
  });
  const VillageTeam = filteredRoles.filter((role) => {
    return role.team === Team.Village;
  });
  const ThirdPartyTeam = filteredRoles.filter((role) => {
    return role.team === Team.ThirdParty;
  });
  return (
    <>
      <YourRoleAlert roleName={yourRoleAssignment?.roleName} />
      {gameData.gameState === GameState.Started &&
        !user.isAnonymous &&
        (!favoriteData || !favoriteData[props.gameID]) && (
          <IonFab vertical="top" horizontal="end" slot="fixed" edge>
            <IonFabButton
              onClick={() =>
                props.firebase.favoriteVillage(
                  gameData.name,
                  props.gameID,
                  gameData.roles
                )
              }
            >
              <IonIcon icon={star} />
            </IonFabButton>
          </IonFab>
        )}
      <IonGrid>
        <IonRow>
          <IonCol size="6">
            <div>Game name: {gameData.name}</div>
            <div>
              {gameData.gameState === GameState.Started &&
                yourRoleAssignment &&
                `Your role: ${yourRoleAssignment.roleName}`}
              {gameData.gameState === GameState.NotStarted &&
                !player &&
                !isModerator && (
                  <IonButton
                    onClick={() => props.firebase.addPlayerToGame(props.gameID)}
                  >
                    Join Game
                  </IonButton>
                )}
            </div>
            {gameData.gameState === GameState.NotStarted && isOwner && (
              <div>
                <CreateRoleModal
                  firebase={props.firebase}
                  gameID={props.gameID}
                />
              </div>
            )}
          </IonCol>
          <IonCol>
            <CountComponent title="Role Count" count={roleCount} />
            {gameData.gameState === GameState.NotStarted && isOwner && (
              <IonButton onClick={startGame}>Start Game</IonButton>
            )}
            {gameData.gameState === GameState.NotStarted &&
              isOwner &&
              !player &&
              !gameData.moderator && (
                <IonButton
                  onClick={() => {
                    props.firebase.assignModerator(props.gameID, user?.uid);
                  }}
                >
                  Claim Moderator
                </IonButton>
              )}
          </IonCol>
          <IonCol>
            <CountComponent title="Player Count" count={playerCount} />
          </IonCol>
        </IonRow>
        <IonRow>
          {gameData.lycanSet && (
            <div className="mysteryLycanDiv">
              The mystery lycan has been picked.
            </div>
          )}
        </IonRow>
      </IonGrid>
      <div>
        Werewolf Team:
        <IonList>
          {EvilTeam.map((role, index) => {
            return (
              <RoleComponent
                key={index}
                role={role}
                firebaseRef={props.firebase.db
                  .collection("games")
                  .doc(props.gameID)}
                firebase={props.firebase}
                isEditable={
                  gameData.gameState === GameState.NotStarted && isOwner
                }
              />
            );
          })}
        </IonList>
        Village Team:
        <IonList>
          {VillageTeam.map((role, index) => {
            return (
              <RoleComponent
                key={index}
                role={role}
                firebaseRef={props.firebase.db
                  .collection("games")
                  .doc(props.gameID)}
                firebase={props.firebase}
                isEditable={
                  gameData.gameState === GameState.NotStarted && isOwner
                }
              />
            );
          })}
        </IonList>
        Third Party:
        <IonList>
          {ThirdPartyTeam.map((role, index) => {
            return (
              <RoleComponent
                key={index}
                role={role}
                firebaseRef={props.firebase.db
                  .collection("games")
                  .doc(props.gameID)}
                firebase={props.firebase}
                isEditable={
                  gameData.gameState === GameState.NotStarted && isOwner
                }
              />
            );
          })}
        </IonList>
        {yourRole && yourRole.picksLycan && !gameData.lycanSet && (
          <LycanPicker gameID={props.gameID} firebase={props.firebase} onSlide={createModeratorSlideAction}/>
        )}
        {gameData.lycanSet &&
          yourRole &&
          yourRole.checksFor === CheckProperties.Wolfy && (
            <WolfyChecker gameID={props.gameID} firebase={props.firebase} onSlide={createModeratorSlideAction}/>
          )}
        {isModerator && (
          <ModeratorList
            gameID={props.gameID}
            gameData={gameData}
            firebase={props.firebase}
          />
        )}
        {!isModerator &&
          (!yourRole ||
            (!(yourRole.picksLycan && !gameData.lycanSet) &&
              !(
                gameData.lycanSet &&
                yourRole.checksFor === CheckProperties.Wolfy
              ))) && (
            <div>
              Players:
              <PlayerList
                gameID={props.gameID}
                gameData={gameData}
                isOwner={isOwner}
                firebase={props.firebase}
                onSlide={createModeratorSlideAction}
              />
            </div>
          )}
      </div>
    </>
  );
};

const GameDetails = withFirebase(GameDetailsBase);

const createRole = (
  roleName: string,
  description: string,
  team: Team,
  picksLycan: boolean,
  wolfy: boolean,
  gameID: string,
  firebase?: Firebase
) => {
  if (firebase) {
    firebase
      .createNewRole(roleName, description, team, picksLycan, wolfy, gameID)
      .then(() => {
        //No Op
      });
  }
};

const getColorForTeam = (team: Team) => {
  switch (team) {
    case Team.ThirdParty:
      return "dark";
    case Team.Wolf:
      return "danger";
    case Team.Village:
      return "success";
    default:
      assertNever(team);
  }
};

function assertNever(x: never): never {
  throw new Error("Unexpected object: " + x);
}

type RoleComponentProps = {
  role: RoleWithData;
  firebase: Firebase;
  isEditable: boolean;
  firebaseRef: firebase.firestore.DocumentReference;
};

const RoleComponent: React.FC<RoleComponentProps> = (props) => {
  const [expanded, setExpanded] = useState(false);
  const color = getColorForTeam(props.role.team);
  return (
    <>
      <IonGrid>
        <IonRow>
          <IonCol
            size="9"
            onClick={() => {
              setExpanded(!expanded);
            }}
          >
            <IonItem
              className={
                expanded
                  ? "RolesComponent__ArrowIconUp"
                  : "RolesComponent__ArrowIconDown"
              }
              detail={
                !!props.role.description ||
                props.role.picksLycan ||
                props.role.wolfy ||
                !!props.role.checksFor
              }
            >
              <IonLabel position="fixed" color={color}>
                {props.role.name}
              </IonLabel>
              {props.role.wolfy && (
                <img
                  className="HeaderComponent__icon"
                  src={"assets/icon/favicon.png"}
                  alt=""
                />
              )}
              {props.role.picksLycan && (
                <img
                  className="HeaderComponent__icon"
                  src={"assets/icon/point.png"}
                  alt=""
                />
              )}
              {props.role.checksFor && <IonIcon icon={eye} />}
            </IonItem>
          </IonCol>
          <IonCol>
            <IonItem>
              <IonInput
                type={"number"}
                inputMode={"numeric"}
                mode={"ios"}
                min={"0"}
                debounce={500}
                value={props.role.quantity.toString()}
                onIonChange={(value) => {
                  props.firebase.setRoleQuantity(
                    props.firebaseRef,
                    Number(value.detail.value),
                    props.role.name
                  );
                }}
                disabled={!props.isEditable}
              />
            </IonItem>
          </IonCol>
        </IonRow>
        {expanded && (
          <div>
            {props.role.description && (
              <IonRow>
                <IonCol>Description: {props.role.description}</IonCol>
              </IonRow>
            )}
            {props.role.wolfy && (
              <IonRow>
                <IonCol>
                  <img
                    className="HeaderComponent__icon"
                    src={"assets/icon/favicon.png"}
                    alt=""
                  />
                  Wolfy
                </IonCol>
              </IonRow>
            )}
            {props.role.picksLycan && (
              <IonRow>
                <IonCol>
                  <img
                    className="HeaderComponent__icon"
                    src={"assets/icon/point.png"}
                    alt=""
                  />
                  Picks the Lycan
                </IonCol>
              </IonRow>
            )}
            {props.role.checksFor && (
              <IonRow>
                <IonCol>
                  <IonIcon size="large" icon={eye} />
                  Checks for: {props.role.checksFor}
                </IonCol>
              </IonRow>
            )}
          </div>
        )}
      </IonGrid>
    </>
  );
};

type PlayerComponentProps = {
  gameID: string;
  playerID: string;
  firebase: Firebase;
  kickPlayer?: (playerID: string) => void;
  canKickPlayer: boolean;
  selection?: string;
  onClick?: () => void;
  check?: string;
  role?: moderationRoleData;
  onSlide?: SlideAction;
};

const PlayerComponent: React.FC<PlayerComponentProps> = (props) => {
  const gameRef = props.firebase.db.collection("games").doc(props.gameID);
  const [gameData, gameDataLoading, gameDataError] = useDocumentData<gameData>(
    gameRef
  );
  const ionItemSliding = useRef<HTMLIonItemSlidingElement>(null);
  if (gameDataLoading || !gameData) {
    return null;
  }
  const playerDisplayName = gameData.players[props.playerID];
  const selectionDisplayName = gameData.players[props.selection || ""];
  return (
    <IonItemSliding ref={ionItemSliding}>
      <IonItem>
        <IonGrid>
          <IonRow>
            <IonCol>
              <IonLabel>{playerDisplayName}</IonLabel>
            </IonCol>
            {props.canKickPlayer && (
              <IonCol>
                <IonButton
                  color="danger"
                  onClick={() =>
                    props.kickPlayer && props.kickPlayer(props.playerID)
                  }
                >
                  Kick
                </IonButton>
              </IonCol>
            )}
            {props.onClick && (
              <IonButton color="primary" onClick={props.onClick}>
                Select
              </IonButton>
            )}
            {props.selection && (
              <IonCol>Selection: {selectionDisplayName}</IonCol>
            )}
            {props.check && <IonCol>Check: {props.check}</IonCol>}
            {props.role && (
              <IonCol>
                <IonLabel>
                  Role:{" "}
                  <IonNote color={getColorForTeam(props.role.team)}>
                    {props.role.roleID}
                  </IonNote>
                </IonLabel>
              </IonCol>
            )}
          </IonRow>
        </IonGrid>
      </IonItem>
      {props.onSlide && (
        <IonItemOptions side="end">
          <IonItemOption
            color="danger"
            onClick={() => {
              ionItemSliding.current?.close();
              props.onSlide?.fn(props.playerID);
            }}
          >
            {props.onSlide.actionName}
          </IonItemOption>
        </IonItemOptions>
      )}
    </IonItemSliding>
  );
};

type CreateRoleModalProps = {
  gameID: string;
};

const CreateRoleModal: React.FC<CreateRoleModalProps & WithFirebaseProps> = (
  props
) => {
  const [showModal, setShowModal] = useState(false);
  const [roleInput, setRoleInput] = useState("");
  const [teamInput, setTeamInput] = useState<Team>(Team.Village);
  const [wolfy, setWolfy] = useState(false);
  const [picksLycan, setPicksLycan] = useState(false);
  const [description, setDescription] = useState<string>("");

  const addNewRole = () => {
    if (roleInput) {
      createRole(
        roleInput,
        description,
        teamInput,
        picksLycan,
        wolfy,
        props.gameID,
        props.firebase
      );
      setRoleInput("");
      setDescription("");
      setWolfy(false);
      setPicksLycan(false);
      setTeamInput(Team.Village);
      setShowModal(false);
    }
  };

  return (
    <div>
      <IonModal isOpen={showModal} onDidDismiss={() => setShowModal(false)}>
        <p>
          <IonInput
            placeholder=" role"
            value={roleInput}
            onIonChange={(e) =>
              setRoleInput((e.target as HTMLInputElement).value)
            }
          >
            Role Name:&nbsp;
          </IonInput>
        </p>
        <IonList>
          <IonRadioGroup
            value={teamInput}
            onIonChange={(e) =>
              setTeamInput((e.target as HTMLInputElement).value as Team)
            }
          >
            <IonListHeader>
              <IonLabel>Team</IonLabel>
            </IonListHeader>
            {Teams.map((team) => {
              return (
                <IonItem key={team}>
                  <IonLabel>{team}</IonLabel>
                  <IonRadio slot="start" value={team} />
                </IonItem>
              );
            })}
          </IonRadioGroup>
        </IonList>
        <IonItem>
          <IonLabel>Wolfy:</IonLabel>
          <IonCheckbox
            onIonChange={(e) => setWolfy((e.target as any).checked)}
            checked={wolfy}
            color="primary"
          />
        </IonItem>
        <IonItem>
          <IonLabel>Picks mystery lycan:</IonLabel>
          <IonCheckbox
            onIonChange={(e) => setPicksLycan((e.target as any).checked)}
            checked={picksLycan}
            color="primary"
          />
        </IonItem>
        <IonItem>
          <IonLabel>Description:</IonLabel>
          <IonTextarea
            placeholder={"description..."}
            maxlength={280}
            value={description}
            onIonChange={(e) => setDescription(e.detail.value!)}
          />
        </IonItem>
        <IonButton
          onClick={() => {
            addNewRole();
          }}
        >
          Add role
        </IonButton>
      </IonModal>
      <IonButton onClick={() => setShowModal(true)}>Add role</IonButton>
    </div>
  );
};

type LycanPickerProps = {
  gameID: string;
  onSlide?: SlideAction;
};

const LycanPicker: React.FC<LycanPickerProps & WithFirebaseProps> = (props) => {
  const [user, initialising, userError] = useAuthState(props.firebase.auth);
  const gameRef = props.firebase.db.collection("games").doc(props.gameID);
  const [lycanData, lycanDataLoading, lycanDataError] = useDocumentData<
    lycanData
  >(props.firebase.db.collection(Collections.PicksLycan).doc(props.gameID));
  const [gameData, gameDataLoading, gameDataError] = useDocumentData<gameData>(
    gameRef
  );
  // const [mySelection]
  if (
    lycanDataLoading ||
    gameDataLoading ||
    initialising ||
    !lycanData ||
    !gameData ||
    !user
  ) {
    return null;
  }

  const allPlayersInAgreement =
    Object.values(lycanData).every((value) => lycanData[user.uid] === value) &&
    lycanData[user.uid];
  return (
    <div>
      Lycan Pickers:
      {allPlayersInAgreement && (
        <IonButton onClick={() => props.firebase.confirmLycan(props.gameID)}>
          Confirm Pick
        </IonButton>
      )}
      <IonList>
        {Object.entries(lycanData).map(([playerID, selectedLycan]) => {
          return (
            <PlayerComponent
              key={playerID}
              gameID={props.gameID}
              playerID={playerID}
              firebase={props.firebase}
              canKickPlayer={false}
              selection={selectedLycan}
              onSlide={props.onSlide}
            />
          );
        })}
      </IonList>
      Players:
      <PlayerList
        gameID={props.gameID}
        gameData={gameData}
        firebase={props.firebase}
        onSelection={(playerID: string) => {
          playerID !== lycanData[user.uid] &&
            props.firebase.setLycan(playerID, props.gameID);
        }}
      />
    </div>
  );
};

type WolfyCheckerProps = {
  gameID: string;
  onSlide?: SlideAction;
};

const WolfyChecker: React.FC<WolfyCheckerProps & WithFirebaseProps> = (
  props
) => {
  const [user, initialising, userError] = useAuthState(props.firebase.auth);
  const gameRef = props.firebase.db.collection("games").doc(props.gameID);
  const [
    yourRoleAssignment,
    yourRoleAssignmentLoading,
    yourRoleAssignmentError,
  ] = useDocumentData<roleAssignment>(
    gameRef.collection("assignments").doc(user && user.uid),
    { idField: "playerID" }
  );
  const [gameData, gameDataLoading, gameDataError] = useDocumentData<gameData>(
    gameRef
  );

  // const [mySelection]
  if (
    gameDataLoading ||
    initialising ||
    yourRoleAssignmentLoading ||
    !yourRoleAssignment ||
    !gameData ||
    !user
  ) {
    return null;
  }
  return (
    <div>
      <p> Make Your Check </p>
      Players:
      <PlayerList
        gameID={props.gameID}
        gameData={gameData}
        firebase={props.firebase}
        playerData={yourRoleAssignment.checks}
        onSlide={props.onSlide}
        onSelection={
          !yourRoleAssignment.usedCheck
            ? (playerID: string) => {
                !yourRoleAssignment.usedCheck &&
                  props.firebase.checkPlayer(playerID, props.gameID);
              }
            : undefined
        }
      />
    </div>
  );
};

type SlideAction = {
  actionName: string;
  fn: (playerID: string) => void;
};

type PlayerListProps = {
  gameID: string;
  gameData: gameData;
  isOwner?: boolean;
  onSelection?: (playerID: string) => void;
  playerData?: { [key: string]: string };
  moderatorData?: { [key: string]: moderationRoleData };
  onSlide?: SlideAction;
};

const PlayerList: React.FC<PlayerListProps & WithFirebaseProps> = (props) => {
  return (
    <IonList>
      {Object.entries(props.gameData.players)
        .sort((playerA, playerB) => {
          if (playerA[1].toUpperCase() < playerB[1].toUpperCase()) {
            return -1;
          }
          if (playerA[1].toUpperCase() > playerB[1].toUpperCase()) {
            return 1;
          }
          return 0;
        })
        .map(([playerID, playerDisplayName]) => {
          return (
            <PlayerComponent
              onClick={
                props.onSelection
                  ? () => {
                      props.onSelection && props.onSelection(playerID);
                    }
                  : undefined
              }
              key={playerID}
              gameID={props.gameID}
              playerID={playerID}
              firebase={props.firebase}
              kickPlayer={(playerID: string) => {
                props.firebase.removePlayerFromGame(props.gameID, playerID);
              }}
              canKickPlayer={
                props.gameData.gameState === GameState.NotStarted &&
                !!props.isOwner
              }
              check={props.playerData && props.playerData[playerID]}
              role={props.moderatorData && props.moderatorData[playerID]}
              onSlide={props.onSlide}
            />
          );
        })}
    </IonList>
  );
};

type ModeratorListProps = {
  gameID: string;
  gameData: gameData;
};

const ModeratorList: React.FC<ModeratorListProps & WithFirebaseProps> = (
  props
) => {
  const moderatorRef = props.firebase.db
    .collection(Collections.Moderation)
    .doc(props.gameID);
  const [
    moderatorInfo,
    moderatorInfoLoading,
    moderatorInfoError,
  ] = useDocumentData<moderationData>(moderatorRef);
  const [renamePlayerModal, setRenamePlayerModal] = useState<string>("");
  const openRenamePlayerModal = (playerID: string) => {
    setRenamePlayerModal(playerID);
  };
  const renamePlayer = (newName: string) => {
    props.firebase
      .renamePlayer(props.gameID, renamePlayerModal, newName)
      .then(() => setRenamePlayerModal(""));
  };
  let moderatorData = {};
  if (moderatorInfo) {
    moderatorData = Object.fromEntries(
      Object.keys(props.gameData.players).map((playerID: string) => {
        return [playerID, moderatorInfo[playerID]];
      })
    );
  }
  return (
    <div>
      <PlayerRenameModal
        showModal={!!renamePlayerModal}
        closeModal={() => setRenamePlayerModal("")}
        submitRename={renamePlayer}
      />
      Players:
      <PlayerList
        gameID={props.gameID}
        gameData={props.gameData}
        firebase={props.firebase}
        moderatorData={moderatorData}
        isOwner={true} // TODO: Not actually true
        onSlide={{ actionName: "Rename", fn: openRenamePlayerModal }}
      />
    </div>
  );
};

type PlayerRenameModalProps = {
  showModal: boolean;
  closeModal: () => void;
  submitRename: (name: string) => void;
};

const PlayerRenameModal: React.FC<PlayerRenameModalProps> = (props) => {
  const [name, setName] = useState("");
  const submitRename = () => {
    const newName = name;
    setName("");
    props.submitRename(newName);
  };
  return (
    <IonModal isOpen={props.showModal} onDidDismiss={() => props.closeModal()}>
      <p>
        <IonTitle>Rename Player</IonTitle>
        <IonInput
          placeholder="name"
          value={name}
          onIonChange={(e) => setName((e.target as HTMLInputElement).value)}
        >
          Name:&nbsp;
        </IonInput>
        <IonButton onClick={submitRename}>submit</IonButton>
      </p>
    </IonModal>
  );
};

export default GamePage;
