import React from "react";
import { useHistory } from "react-router-dom";
import {
  IonButton,
  IonButtons,
  IonFooter,
  IonTitle,
  IonToolbar,
} from "@ionic/react";

const Footer: React.FC<{}> = (props) => {
  const history = useHistory();
  return (
    <IonFooter>
      <IonToolbar>
        <IonButtons>
          <IonButton
            onClick={() => history.push(`/acknowledgments`)}
            color="secondary"
          >
            Acknowledgments
          </IonButton>
        </IonButtons>
      </IonToolbar>
    </IonFooter>
  );
};

export default Footer;
