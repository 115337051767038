import app from "firebase/app";
import "firebase/firestore";
import "firebase/functions";

import * as firebase from "firebase";
import { CheckProperties, Collections, GameState, Team } from "../common/enums";
import { Role, RoleWithData } from "../common/types";
import * as firebaseui from "firebaseui";

const firebaseConfig = {
  apiKey: "AIzaSyDj-FBq1XS0Y1ChGGxHaUAUmVuseHaqTHc",
  authDomain: "werewolfbuddy.com",
  databaseURL: "https://werewolfbuddy-d1a11.firebaseio.com",
  projectId: "werewolfbuddy-d1a11",
  storageBucket: "werewolfbuddy-d1a11.appspot.com",
  messagingSenderId: "71241851445",
  appId: "1:71241851445:web:7d7d8c0792abcd6c12477c",
  measurementId: "G-41DZ702KV1",
};

let firebaseSinglton: Firebase;

export const getFirebaseInstance = () => {
  if (!firebaseSinglton) {
    firebaseSinglton = new Firebase();
  }
  return firebaseSinglton;
};

class Firebase {
  db: firebase.firestore.Firestore;
  functions: firebase.functions.Functions;
  auth: firebase.auth.Auth;
  firebaseUIConfig: firebaseui.auth.Config;
  user?: app.User;

  constructor() {
    app.initializeApp(firebaseConfig);
    this.db = app.firestore();
    this.functions = app.functions();
    this.auth = app.auth();
    this.firebaseUIConfig = {
      // Popup signin flow rather than redirect flow.
      signInFlow: "popup",
      signInSuccessUrl: "/",
      signInOptions: [firebase.auth.GoogleAuthProvider.PROVIDER_ID],
    };
    firebase.auth().onAuthStateChanged((user) => {
      this.user = user || undefined;
    });
    // this.signUserInAnonymously();
  }

  async createNewRoom(roomName: string): Promise<boolean> {
    //TODO: handle room being taken differently than failing to create room
    const currentUser = this.user;
    if (!currentUser) {
      return false;
    }
    const roomRef = this.db.collection("rooms").doc(roomName);
    const userRef = this.db.collection("users").doc(currentUser.uid);
    const createdNewRoom = await this.db.runTransaction(async (transaction) => {
      const roomDoc = await transaction.get(roomRef);
      if (roomDoc.exists) {
        return false;
      } else {
        transaction.set(roomRef, {
          name: roomName,
          owner: currentUser.uid,
        });
        transaction.set(
          userRef,
          {
            rooms: firebase.firestore.FieldValue.arrayUnion(roomName),
          },
          { merge: true }
        );
        return true;
      }
    });
    if (createdNewRoom) {
      this.addPlayerToRoom(roomName);
    }
    return createdNewRoom;
  }

  async createNewGame(
    gameName: string,
    roomName: string,
    village?: { [key: string]: RoleWithData }
  ): Promise<firebase.firestore.DocumentReference | false> {
    const currentUser = this.user;
    if (!currentUser) {
      return false;
    }
    const premadeRoles: Role[] = [
      {
        name: "Seer",
        team: Team.Village,
        wolfy: false,
        picksLycan: false,
        description: "Gets to check for a werewolf each night",
        checksFor: CheckProperties.Wolfy,
      },
      {
        name: "Villager",
        team: Team.Village,
        wolfy: false,
        picksLycan: false,
        description: "Just a vanilla Villager",
      },
      {
        name: "Wolf",
        team: Team.Wolf,
        wolfy: true,
        picksLycan: true,
        description: "Kills a villager each night",
      },
      {
        name: "Minion",
        team: Team.Wolf,
        wolfy: false,
        picksLycan: false,
        description: "Gets a check for a werewolf each night",
        checksFor: CheckProperties.Wolfy,
      },
    ];
    // const premadeRoles = ["Seer", "Villager", "Wolf", "Hunter", "Witch", "Thing", "Bodyguard", "Priest"];
    const gameRef = await this.db.collection("games").add({
      roomName: roomName,
      name: gameName,
      gameState: GameState.NotStarted,
      owner: currentUser.uid,
      date: new Date(),
      players: {},
    });
    if (village) {
      Object.values(village).forEach((role) =>
        this.createPredefinedRole(role, gameRef.id)
      );
    } else {
      premadeRoles.forEach((role) =>
        this.createPredefinedRole(role, gameRef.id)
      );
    }

    return gameRef;
  }

  async assignModerator(gameID: string, userID: string) {
    const gameRef = this.db.collection(Collections.Games).doc(gameID);
    return gameRef.set({ moderator: userID }, { merge: true });
  }

  async renamePlayer(gameID: string, userID: string, newName: string) {
    const gameRef = this.db.collection(Collections.Games).doc(gameID);
    return gameRef.update({
      ["players." + userID]: newName,
    });
  }

  async addPlayerToRoom(roomName: string) {
    if (this.user) {
      const roomPlayerRef = this.db
        .collection("rooms")
        .doc(roomName)
        .collection("players")
        .doc(this.user.uid);
      const batch = this.db.batch();
      batch.set(
        roomPlayerRef,
        {
          name: this.user.displayName,
        },
        { merge: true }
      );
      const userRef = this.db.collection("users").doc(this.user.uid);
      batch.set(
        userRef,
        {
          rooms: firebase.firestore.FieldValue.arrayUnion(roomName),
        },
        { merge: true }
      );
      await batch.commit();
      return true;
    }
    return false;
  }

  removePlayerFromGame(gameID: string, playerID: string) {
    return this.db
      .collection("games")
      .doc(gameID)
      .set(
        {
          players: { [playerID]: firebase.firestore.FieldValue.delete() },
        },
        { merge: true }
      );
  }

  addPlayerToGame(gameID: string) {
    if (!this.user) {
      return;
    }
    this.db
      .collection("games")
      .doc(gameID)
      .set(
        {
          players: { [this.user.uid]: this.user.displayName },
        },
        { merge: true }
      );
  }

  favoriteVillage(
    name: string,
    gameID: string,
    roles: { [key: string]: RoleWithData }
  ) {
    if (!this.user) {
      return;
    }
    const filteredRoles: { [key: string]: RoleWithData } = {};
    for (const role in roles) {
      if (roles[role].quantity) {
        filteredRoles[role] = roles[role];
      }
    }
    this.db
      .collection("favorites")
      .doc(this.user.uid)
      .set(
        {
          [gameID]: {
            villageName: name,
            roles: filteredRoles,
          },
        },
        { merge: true }
      );
  }

  deleteFavoriteVillage(favoriteVillageID: string) {
    if (!this.user) {
      return;
    }
    this.db
      .collection("favorites")
      .doc(this.user.uid)
      .set(
        {
          [favoriteVillageID]: firebase.firestore.FieldValue.delete(),
        },
        { merge: true }
      );
  }

  createPredefinedRole(
    role: Role & { quantity?: number; id?: string },
    gameID: string
  ) {
    return this.db
      .collection("games")
      .doc(gameID)
      .update({
        ["roles." + role.name]: {
          name: role.name,
          quantity: role.quantity || 0,
          wolfy: role.wolfy,
          picksLycan: role.picksLycan,
          team: role.team,
          checksFor: role.checksFor || null,
          description: role.description,
        },
      });
  }

  createNewRole(
    roleName: string,
    description: string,
    team: Team,
    picksLycan: boolean,
    wolfy: boolean,
    gameID: string,
    quantity = 1
  ): Promise<void> {
    return this.db
      .collection("games")
      .doc(gameID)
      .update({
        ["roles." + roleName.replace(".", "")]: {
          name: roleName,
          description: description,
          quantity: quantity,
          wolfy: wolfy,
          picksLycan: picksLycan,
          team: team,
        },
      });
  }

  setLycan(lycanID: string, gameID: string) {
    if (!this.user) {
      return;
    }
    return this.db
      .collection(Collections.PicksLycan)
      .doc(gameID)
      .set(
        {
          [this.user.uid]: lycanID,
        },
        { merge: true }
      );
  }

  async confirmLycan(gameID: string) {
    const user = this.user;
    if (!user) {
      return false;
    }
    const lycanRef = this.db.collection(Collections.PicksLycan).doc(gameID);
    const gameRef = this.db.collection(Collections.Games).doc(gameID);

    const confirmLycan = await this.db.runTransaction(async (transaction) => {
      const lycanSnap = await transaction.get(lycanRef);
      const lycanData = lycanSnap.data();
      if (!lycanData || lycanData.lycan) {
        return false;
      }
      const allPlayersInAgreement =
        Object.values(lycanData).every(
          (value) => lycanData[user.uid] === value
        ) && lycanData[user.uid];
      if (!allPlayersInAgreement) {
        return false;
      }
      transaction.set(
        lycanRef,
        { lycan: lycanData[user.uid] },
        { merge: true }
      );
      transaction.set(gameRef, { lycanSet: true }, { merge: true });
    });
    return confirmLycan;
  }

  async checkPlayer(playerID: string, gameID: string) {
    const user = this.user;
    if (!user) {
      return false;
    }
    const lycanRef = this.db.collection(Collections.PicksLycan).doc(gameID);
    const checkAssignmentRef = this.db
      .collection(Collections.Games)
      .doc(gameID)
      .collection(Collections.Assignments)
      .doc(playerID);
    const checkerAssignmentRef = this.db
      .collection(Collections.Games)
      .doc(gameID)
      .collection(Collections.Assignments)
      .doc(user.uid);
    const getCheck = await this.db.runTransaction(async (transaction) => {
      const lycanSnap = await transaction.get(lycanRef);
      const lycanData = lycanSnap.data();
      if (!lycanData || !lycanData.lycan) {
        return false;
      }
      const checkAssignmentSnap = await transaction.get(checkAssignmentRef);
      const checkAssignmentData = checkAssignmentSnap.data();
      if (!checkAssignmentData) {
        return false;
      }
      // TODO: Check this person can check
      const checkerAssignmentSnap = await transaction.get(checkerAssignmentRef);
      const checkerAssignmentData = checkerAssignmentSnap.data();
      if (!checkerAssignmentData || checkerAssignmentData.usedCheck) {
        return false;
      }
      const isWolfy = checkAssignmentData.wolfy || lycanData.lycan === playerID;
      transaction.set(
        checkerAssignmentRef,
        {
          usedCheck: true,
          checks: { [playerID]: isWolfy ? "Wolf" : "Villager" },
        },
        { merge: true }
      );
      transaction.set(checkAssignmentRef, checkAssignmentData, { merge: true });
      transaction.set(lycanRef, lycanData, { merge: true });
    });
    return getCheck;
  }

  getRoomRoles(
    roomID: string,
    callback: (rolesCollection: firebase.firestore.QuerySnapshot) => void
  ) {
    this.db
      .collection("rooms")
      .doc(roomID)
      .collection("roles")
      .onSnapshot(callback);
  }

  setRoleQuantity(
    roleRef: firebase.firestore.DocumentReference,
    quantity: number,
    role: string
  ) {
    roleRef.update({
      ["roles." + role.replace(".", "") + ".quantity"]: quantity,
    });
  }

  getRoomExists(roomID: string): Promise<boolean> {
    return this.db
      .collection("rooms")
      .doc(roomID)
      .get()
      .then((roomSnapShot) => {
        return roomSnapShot.exists;
      });
  }

  startGame(gameID: string) {
    const startGame = this.functions.httpsCallable("startGame");
    return startGame({ gameID: gameID });
  }

  getMyRole(
    roomID: string,
    playerID: string,
    callback: (assignmentDoc: firebase.firestore.DocumentSnapshot) => void
  ) {
    this.db
      .collection(Collections.Rooms)
      .doc(roomID)
      .collection(Collections.Assignments)
      .doc(playerID)
      .onSnapshot(callback);
  }

  signUserInAnonymously() {
    return firebase
      .auth()
      .signInAnonymously()
      .catch(function (error) {
        // Handle Errors here.
        const errorCode = error.code;
        const errorMessage = error.message;
        console.error(
          `Failed to log in anonymously Error code: ${errorCode}, Error Message: ${errorMessage}`
        );
      });
  }

  signUserOut() {
    return firebase.auth().signOut();
  }

  getCurrentUser() {
    return this.auth.currentUser;
  }
}

export default Firebase;
